import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterCapital',
  pure: false,
})
export class FirstLetterCapitalPipe implements PipeTransform {
  transform(item: string | number | undefined): string {
    if (!item) return '';

    const strItem = item.toString();
    return strItem.substring(0, 1).toLocaleUpperCase() + strItem.substring(1, strItem.length).replace('_', ' ').toLocaleLowerCase();
  }
}
