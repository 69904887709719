<div style="font-weight: bold; color: black; margin-bottom: 5px">
  {{ vesselName }}
  <small style="color: gray">({{ vesselMmsi }})</small>
</div>
<div style="color: black">
  <p *ngIf="historyObject.time">Last update: {{ convertToTime(historyObject.time) | localDate }}</p>
  <div>
    <b>Course:</b>
    {{ historyObject.c }} °
  </div>
  <div *ngIf="heading">
    <b>Heading:</b>
    {{ heading }} °
  </div>
  <div>
    <b>True heading:</b>
    {{ historyObject.t_h }} °
  </div>
</div>
