import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  pure: false, // Use pure: true for better performance if input array does not change frequently
})
export class OrderByPipe implements PipeTransform {
  transform<T>(array: T[] | null, field: string, direction: 'asc' | 'desc' = 'asc', sortArray?: any[]): T[] {
    if (!array || !field) return [];

    const isAsc = direction === 'asc';

    return array.sort((a: T, b: T) => {
      const ae = a[field as keyof T];
      const be = b[field as keyof T];

      if (ae === undefined && be === undefined) return 0;
      if (!ae) return isAsc ? 1 : -1;
      if (!be) return isAsc ? -1 : 1;
      if (ae === be) return 0;

      if (!sortArray) {
        return (isAsc ? 1 : -1) * ae.toString().toLowerCase().localeCompare(be.toString().toLowerCase());
      } else {
        return (isAsc ? 1 : -1) * (sortArray.indexOf(ae) > sortArray.indexOf(be) ? 1 : -1);
      }
    });
  }
}
