import { Observable, shareReplay } from 'rxjs';
import { RegionClient, Region } from './apis/cloud.service';
import { Injectable } from '@angular/core';

@Injectable()
export class FleetmanagerApiService {
  public url = 'https://api.dotocean.eu/cerebro/';
  public regions$: Observable<Region[]>;

  constructor(regionClient: RegionClient) {
    this.regions$ = regionClient.getRegions().pipe(shareReplay(1));
  }
}
