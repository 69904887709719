import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import L from 'leaflet';

export interface MapBounds {
  latSW: number;
  lngSW: number;
  latNE: number;
  lngNE: number;
}

export interface MapBoundsMinMax {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
}

@Injectable()
export class MapService implements OnDestroy {
  public mapBounds?: MapBoundsMinMax;
  public isServiceDestroyed = false;
  public mapLoaded$ = new BehaviorSubject<boolean>(false);
  public Map?: L.Map;

  private _layerControl?: L.Control.Layers;
  private readonly overlayStorageKey = 'overlays';

  private _overlays: string[] = [];

  public async initMap(addObjectOverlay = true, mapName = 'map', showLayerControl = true, showScale = true, showMeasurementTool = false) {
    this.isServiceDestroyed = false;
    if (this.isServiceDestroyed) return;

    this.Map = L.map(mapName, {
      zoomControl: false,
      attributionControl: false,
    });
    this.Map.whenReady(() => {
      setTimeout(() => {
        this.Map?.invalidateSize();
        this.setBounds();
        this.mapLoaded$.next(true);
      }, 100);
    });

    this.getOpenStreetMapLayer().addTo(this.Map);
    this.addLayers(addObjectOverlay, showLayerControl);
    if (showScale) L.control.scale({ position: 'bottomright', imperial: false }).addTo(this.Map);

    this.setViewToDotOcean();

    if (showMeasurementTool) {
      // this.Map.addControl(L.control.Measure({ primaryLengthUnit: 'meters', secondaryLengthUnit: 'kilometers',
      //    primaryAreaUnit: 'sqmeters', secondaryAreaUnit: undefined,
      //     activeColor: 'red'
      //  }));
    }
  }

  private addLayers(addObjectOverlay: boolean, showLayerControl: boolean) {
    if (!this.Map) return;

    const groups: Record<string, L.LayerGroup> = {};

    const selectedOverlays = localStorage.getItem(this.overlayStorageKey);

    if (selectedOverlays) this._overlays = JSON.parse(selectedOverlays) || [];

    if (addObjectOverlay) {

      this.objectGroup = new L.LayerGroup();
      const openSeaMapGroup = new L.LayerGroup();
      openSeaMapGroup.addLayer(this.getOpenSeaMapLayer());

      groups['OpenSeaMap'] = openSeaMapGroup;
      groups['Objects'] = this.objectGroup;

      if (!showLayerControl || !selectedOverlays || this._overlays.indexOf('Objects') > -1) this.Map.addLayer(this.objectGroup);
      if (!showLayerControl || !selectedOverlays || this._overlays.indexOf('OpenSeaMap') > -1) this.Map.addLayer(openSeaMapGroup);

      if (this._overlays.indexOf('Objects') == -1) this._overlays.push('Objects');
      if (this._overlays.indexOf('OpenSeaMap') == -1) this._overlays.push('OpenSeaMap');
    }

    if (showLayerControl) {
      this._layerControl = L.control.layers(undefined, groups, { position: 'topleft' }).addTo(this.Map);

      this.subscribeTo_OverlayAdd(undefined);
      this.subscribeTo_OverlayRemove(undefined);
    }
  }

  public objectGroup?: L.LayerGroup;

  private getOpenStreetMapLayer() {
    return L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    });
  }

  private getOpenSeaMapLayer() {
    return L.tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', {
      bounds: [
        [-90, -180],
        [90, 180],
      ],
      noWrap: true,
      maxZoom: 22,
    });
  }

  public subscribeTo_Move(fn: L.LeafletEventHandlerFn) {
    this.Map?.on('move', fn);
  }

  public subscribeTo_Click(fn: L.LeafletMouseEventHandlerFn) {
    this.Map?.on('click', fn);
  }

  public subscribeTo_MoveEnd(fn: L.LeafletEventHandlerFn | undefined) {
    this.Map?.on(
      'moveend',
      fn ??
        (async () => {
          await this.setBounds();
        })
    );
  }

  public subscribeTo_OverlayAdd(fn: L.LayersControlEventHandlerFn | undefined) {
    this.Map?.on(
      'overlayadd',
      fn ??
        ((e: any) => {
          this.addOverlay(e.name);
        })
    );
  }

  public subscribeTo_OverlayRemove(fn: L.LayersControlEventHandlerFn | undefined) {
    this.Map?.on(
      'overlayremove',
      fn ??
        ((e: any) => {
          this.removeOverlay(e.name);
        })
    );
  }

  public addOverlay(name: any) {
    if (this._overlays.indexOf(name) == -1) {
      this._overlays.push(name);
      this.saveSelectedOverlays();
    }
  }

  public removeOverlay(name: any) {
    this._overlays.splice(this._overlays.indexOf(name), 1);
    this.saveSelectedOverlays();
  }

  saveSelectedOverlays() {
    if (!this.Map) return;
    localStorage.setItem(this.overlayStorageKey, JSON.stringify(this._overlays));
  }

  public setViewToDotOcean() {
    if (!this.Map) return;
    this.Map.setView([51.20151, 3.196264], 16);
  }

  public setDefaultView() {
    if (!this.Map) return;
    this.Map.setView([51.20151, 3.196264], 1);
  }

  public removeLayer(layer: L.Layer) {
    if (this.isServiceDestroyed) return;
    this.Map?.removeLayer(layer);
  }

  public async setBounds() {
    if (!this.Map) return;

    const bounds = this.Map.getBounds();
    this.mapBounds = {
      minLat: bounds.getSouthWest().lat,
      maxLat: bounds.getNorthEast().lat,
      minLng: bounds.getSouthWest().lng,
      maxLng: bounds.getNorthEast().lng,
    };
  }

  public getBounds(): MapBounds {
    return {
      latSW: this.mapBounds?.minLat ?? 0,
      lngSW: this.mapBounds?.minLng ?? 0,
      latNE: this.mapBounds?.maxLat ?? 0,
      lngNE: this.mapBounds?.maxLng ?? 0,
    };
  }

  public Dispose() {
    this.isServiceDestroyed = true;
    if (this.Map) {
      this.Map.remove();
      this.Map = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.Dispose();
  }
}
