import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ThemeService {
  public activeTheme$ = new BehaviorSubject<string>('light');

  constructor() {
    const theme = localStorage.getItem('theme');
    if (theme === null) {
      localStorage.setItem('theme', 'light');
      return;
    }

    this.activeTheme$.next(theme);
  }

  public toggleTheme(): void {
    const theme = this.activeTheme$.value === 'dark' ? 'light' : 'dark';

    localStorage.setItem('theme', theme);
    this.activeTheme$.next(theme);
    this.setTheme();
  }

  public setTheme(): void {
    document.documentElement.setAttribute('data-bs-theme', this.activeTheme$.value);
  }
}