import { Component, Input } from '@angular/core';
import { GetObjectHistoryResponse } from '@dotocean/virtualworld-ngx-services';
import { CommonModule } from '@angular/common';
import { ObjectNavigation } from '../../../models/object-navigation';
import moment from 'moment';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';

@Component({
  selector: 'app-map-hover',
  standalone: true,
  imports: [CommonModule,LocalDatePipe],
  templateUrl: './map-hover.component.html',
  styleUrl: './map-hover.component.scss',
})
export class MapHoverComponent {
  @Input({ required: true })
  public vesselName?: string;

  @Input({ required: true })
  public vesselMmsi: number;

  @Input({ required: true })
  public historyObject: GetObjectHistoryResponse | ObjectNavigation;

  @Input({ required: true })
  public heading: number;

  convertToTime(time: number | Date) : Date {
    if (typeof time === 'number') return moment.unix(time / 1000).local().toDate();

    return time;
  }
}
