import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumFilter',
    pure: false
})
export class EnumFilterPipe implements PipeTransform {
    transform(items: any[], enumValue: any): any[] {
        return items.filter(item => item.type === enumValue);
    }
}