import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from '../services/theme-service';

@Directive({
  selector: '[themedImage]',
})
export class ThemedImageDirective implements OnInit, OnDestroy, OnChanges {
  @Input() srcLight: string;
  @Input() srcDark: string;
  @Input() themeReverse: boolean = false;

  private themeSubscription$: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    // Subscribe to theme changes
    this.themeSubscription$ = this.themeService.activeTheme$.subscribe(
      (theme) => {
        this.updateSrc(theme);
      }
    );

    // Initialize with the current theme
    this.updateSrc(this.themeService.activeTheme$.value);
  }

  ngOnDestroy() {
    if (this.themeSubscription$) {
      this.themeSubscription$.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['srcLight'] || changes['srcDark'] || changes['themeReverse']) {
      // Re-apply the image source based on the new inputs
      this.updateSrc(this.themeService.activeTheme$.value);
    }
  }

  private updateSrc(activeTheme: string) {
    const darksrc = !this.themeReverse ? this.srcDark : this.srcLight;
    const lightsrc = !this.themeReverse ? this.srcLight : this.srcDark;
    const src = activeTheme.toUpperCase() === 'DARK' ? darksrc : lightsrc;
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);
  }
}
