<div class="mmsi-query"
[class.dropdown-small]="useSmallDropdown"
>
  <!-- https://ng-bootstrap.github.io/#/components/typeahead/examples -->
  <label for="exampleFormControlInput1" class="form-label" *ngIf="!usePlaceholder">Search MMSI or name</label>
  <input
    [(ngModel)]="vesselQuery"
    (ngModelChange)="onVesselQueryChanged($event)"
    name="mmsi-query"
    type="search"
    class="form-control mmsi-query-input"
    aria-label="Search"
    [ngbTypeahead]="searchName"
    (selectItem)="onSelectedItemChanged($event.item)"
    [appAutoFocusCondition]="true"
    [resultFormatter]="searchFormatter"
    [inputFormatter]="searchFormatter"
    (keyup.arrowDown)="scroll($event)"
    (keyup.arrowUp)="scroll($event)"
    [placeholder]="usePlaceholder ? 'Search MMSI or name' : ''"
  />
  @if (searchingQuery) {
  <small class="form-text text-muted mmsi-query-overlay">searching...</small>
  }
</div>
