<ol *ngIf="isLoading" class="list-group">
  <li class="list-group-item">
    <p class="placeholder-glow m-0">
      <span style="width: 190px" class="placeholder float-end"></span>
      <span style="width: 120px" class="placeholder"></span>
      <br />
      <span style="width: 100px" class="placeholder"></span>
    </p>
  </li>
</ol>
