import { NgModule } from '@angular/core';
import { EnumFilterPipe } from '../pipes/enum-filter';
import { OrderByPipe } from '../pipes/order-by';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxJoystickComponent } from './ngx-joystick/ngx-joystick.component';
import { FirstLetterCapitalPipe } from '../pipes/first-letter-capital';
import { ThemedImageDirective } from '../directives/image-theme.directive';
import { AutoFocusConditionDirective } from '../directives/auto-focus-condition.directive ';
import { LoadingListComponent } from '../app/shared/loading-list/loading-list.component';
import { EmptyListComponent } from '../app/shared/empty-list/empty-list.component';
import { LocalDatePipe } from '../pipes/local-date.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconCardComponent } from '../app/shared/icon-card/icon-card.component';

@NgModule({
  declarations: [    
    EnumFilterPipe,
    OrderByPipe,
    FirstLetterCapitalPipe,
    NgxJoystickComponent,
    ThemedImageDirective,
    AutoFocusConditionDirective,
  ],
  imports: [CommonModule, FormsModule, RouterModule, LoadingListComponent, EmptyListComponent, NgbTooltipModule, IconCardComponent, LocalDatePipe],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LocalDatePipe,
    EnumFilterPipe,
    OrderByPipe,
    FirstLetterCapitalPipe,
    NgxJoystickComponent,
    ThemedImageDirective,
    AutoFocusConditionDirective,
    LoadingListComponent,
    EmptyListComponent,
    NgbTooltipModule, IconCardComponent,
  ],
})
export class FleetManagerComponents {}
