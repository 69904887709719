import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { ThemeService } from '../../../services/theme-service';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-component-header',
  standalone: true,
  imports: [FleetManagerComponents, NgbDropdownModule],
  templateUrl: './component-header.component.html',
  styleUrl: './component-header.component.scss',
})
export class ComponentHeaderComponent implements OnInit {
  @ViewChild('dropdown') dropdown: NgbDropdown;

  @Input({ required: true }) public title!: string;
  @Input() public newButtonText: string;
  @Input() public isViewGridActive = true;
  @Output() public isViewGridActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public activeFilters?: number;
  @Input() public enableSearching = true;
  @Input() public enableViewToggling = true;

  @Output() public addNewClicked = new EventEmitter();
  @Output() public searching = new EventEmitter<string[]>();

  @Output() public applyFilter = new EventEmitter();
  @Output() public filterPopupOpening = new EventEmitter();
  @Output() public resetFilter = new EventEmitter();

  public searchValue?: string;
  public searchValueUpdate = new Subject<string[]>();
  private currentEmittedValue?: string[] = undefined;

  private readonly isViewGridActiveStorageKey = 'activeView.grid';

  constructor(public readonly themeService: ThemeService) {}

  public checkDropDown() {
    if (this.dropdown.isOpen()) {
      this.filterPopupOpening.emit();
    }
  }

  public ngOnInit(): void {
    // Debounce search.
    this.searchValueUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.searching.emit(value);
    });
    const view = localStorage.getItem(this.isViewGridActiveStorageKey);
    if (view != null) this.setActiveView(view === '1');
  }

  public searchValueChanged() {
    const newEmitValue =
      this.searchValue
        ?.trim()
        .toLowerCase()
        .split(' ')
        .filter((obj) => !!obj) ?? [];
    //Do this so we dont filter when the search values do not change
    if (JSON.stringify(newEmitValue) === JSON.stringify(this.currentEmittedValue)) {
      return;
    }

    this.searchValueUpdate.next(newEmitValue);
    this.currentEmittedValue = newEmitValue;
  }

  public toggleActiveView(): void {
    this.setActiveView(!this.isViewGridActive);
  }

  private setActiveView(viewGridActive: boolean) {
    this.isViewGridActive = viewGridActive;
    localStorage.setItem(this.isViewGridActiveStorageKey, this.isViewGridActive ? '1' : '0');
    this.isViewGridActiveChange.emit(this.isViewGridActive);
  }

  public close() {
    this.dropdown.close();
  }
}
