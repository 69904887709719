import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { OperatorFunction, Observable, debounceTime, distinctUntilChanged, tap, switchMap, of, map, catchError } from 'rxjs';
import { AssetClient, ResData } from '../../../services/apis/cloud.service';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';

@Component({
  selector: 'app-vessel-search',
  standalone: true,
  imports: [FleetManagerComponents, NgbTypeaheadModule],
  templateUrl: './vessel-search.component.html',
  styleUrl: './vessel-search.component.scss',
})
export class VesselSearchComponent {
  public vesselQuery = '';
  public searchingQuery = false;
  public searchFormatter = (info: ResData) => (info ? `${info.name} - ${info.id}` : '');

  private readonly assetClient = inject(AssetClient);

  @Input() public usePlaceholder = true;
  @Input() public useSmallDropdown = false;

  private _selectedItem: ResData | undefined;
  public get selectedItem(): ResData | undefined {
    return this._selectedItem;
  }
  @Input({ required: true })
  public set selectedItem(selectedItem: ResData | undefined) {
    this._selectedItem = selectedItem;
    if (!this._selectedItem) {
      this.vesselQuery = '';
    }
  }

  @Output() public selectedItemChange = new EventEmitter<ResData | undefined>();

  public searchName: OperatorFunction<string, readonly ResData[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.searchingQuery = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);

        return this.assetClient.getInfo(term).pipe(
          map((infos) => infos.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))),
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.searchingQuery = false))
    );

  // https://github.com/ng-bootstrap/ng-bootstrap/issues/1119#issuecomment-621156541
  scroll($event: any) {
    const elem = $event.currentTarget.nextElementSibling.getElementsByClassName('active')[0];
    elem.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  }

  public onSelectedItemChanged(selectedItem: ResData | undefined) {
    this.selectedItem = selectedItem;
    this.selectedItemChange.emit(selectedItem);
  }

  onVesselQueryChanged(value: string) {
    if (!value) {
      // Input is cleared
      this.onSelectedItemChanged(undefined);
    }
  }
}
