<div class="empty-list--title">
  {{ title }}
</div>

<button
  *ngIf="addButtonText"
  [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-primary' : 'btn-light'"
  type="button"
  class="btn mt-3"
  (click)="addClicked()"
>
  <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
    <use href="bootstrap-icons.svg#plus" />
  </svg>
  {{ addButtonText }}
</button>


<!--

<div *ngIf="alerts.length === 0 && !isLoading" class="col-12 text-center">
  <div class="lead mt-5">
    There are currently no alerts
    <br />
    <button *ngIf="activeTheme === 'light'" type="button" class="btn btn-primary mt-3" (click)="openModal()">
      <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
        <use href="bootstrap-icons.svg#plus" />
      </svg>
      New alert
    </button>
    <button *ngIf="activeTheme === 'dark'" type="button" class="btn btn-light mt-3" (click)="openModal()">
      <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
        <use href="bootstrap-icons.svg#plus" />
      </svg>
      New alert
    </button>
  </div>
</div> -->
