import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ThemeService } from '../../../services/theme-service';

@Component({
  selector: 'app-empty-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './empty-list.component.html',
  styleUrl: './empty-list.component.scss',
})
export class EmptyListComponent {
  @Input({ required: true }) public title!: string;

  @Input() public addButtonText?: string;

  @Output() add = new EventEmitter<void>();

  public readonly themeService = inject(ThemeService);

  public addClicked() {
    this.add.emit();
  }
}
