import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAutoFocusCondition]'
})
export class AutoFocusConditionDirective implements OnChanges {

  @Input('appAutoFocusCondition') focusCondition: boolean;

  constructor(private readonly el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['focusCondition'] && this.focusCondition) {
      // Delay focusing to ensure the element is fully rendered
      setTimeout(() => {
        this.el.nativeElement.focus();
      });
    }
  }
}
